<template>
  <v-dialog  v-model="dialog" persistent max-width="290">
    <v-card>
      <v-card-title class="text-h5"> </v-card-title>
      <v-card-text>
        <div class="d-flex align-center">
          <v-icon large color="red darken-2"> mdi-alert </v-icon>
          <div class="text-h6 pa-3">Want to delete?</div>
        </div></v-card-text
      >
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="dialog = false"> No </v-btn>
        <v-btn color="red darken-1" text @click="remove()"> Yes </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
export default {
  components: {
    //
  },
  computed: mapState({
    //
  }),
  props: ["callbackDelete"],
  data: () => ({
    dialog: false,
  }),
  created() {
    //
  },
  mounted() {
    //
  },
  methods: {
    openDialog() {
      this.dialog = true;
    },
    remove() {
      this.callbackDelete();
      this.dialog = false;
    },
  },
};
</script>