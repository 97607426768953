<template>
	<div>
		<!-- <div class="pb-2 text-left">
		{{$t("view.PageBackendStudent.add_new_1_description")}}<br/>	{{$t("view.PageBackendStudent.add_new_2_description")}}
		</div> -->
		<div class="text-left mb-3">
			<b>Student Names and Previous year clssroom name(optional)</b><br/>
			One name per row.<br/>
			You may copy and paste directly from Excel.<br/>
      It can be a list of mixing new students or students from previous year.
		</div>
		<div class="name-textarea">
			<v-textarea
			autofocus
				outlined
				name="input-7-1"
				placeholder="Bruce Lee
P. Ramlee       1A
Mohandas Gandhi
Marie Curie"
				v-model="students"
				:rules="rules"
        @keydown="handleInput($event)"
			></v-textarea>
		</div>
	</div>
</template>

<script>
// import i18n from '@/plugins/i18n'
export default {
	props: ["callbackSelect", "seletedStudents"],
	data: () => ({
		students: "",
		filterStudents: [],
		name: null,
		// rules: [(v) => !!v || i18n.t("rules.name")],
		rules: [(v) => !!v || "Please fill in the names"],
	}),
	watch: {
		students: function (val) {
			this.students = val;
			this.filterStudents = [];
			var filterStudents = [];
			this.name = val.split("\n");
			this.name?.forEach((line) => {
				const values = line.split("\t");
				filterStudents.push({ name: values[0], classroom: values[1]?this.$_.replace(values[1], /\s/g, '').toUpperCase():null });
			});
			this.filterStudents = filterStudents;
			this.callbackSelect(this.filterStudents);
		},
	},
	methods: {
		handleInput(e) {
			if (e.key == "Tab") {
				e.preventDefault();
				let startText = this.students.slice(0, e.target.selectionStart);
				let endText = this.students.slice(e.target.selectionStart);
				this.students = `${startText}\t${endText}`;
				e.target.selectionEnd = e.target.selectionStart + 1;
			}
		},
	},
	mounted() {
    for (var i = 0; i < this.seletedStudents.length; i++) {
			if (this.seletedStudents[i].classroom) {
				this.students = this.students + this.seletedStudents[i].selectedName.name + "\t"+ this.seletedStudents[i].classroom+"\n";
			}
			else {
				this.students = this.students + this.seletedStudents[i].selectedName.name + "\n";
			}
		}
   
	},
};
</script>

<style>
.name-textarea {
	-moz-tab-size: 24 !important; /* Firefox */
	tab-size: 24 !important;
}
</style>